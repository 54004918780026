<template>
  <v-sheet>
    <v-row class="mb-1 pr-2">
      <v-col class="pl-0 pr-0">
        <v-sheet height="64">
          <v-toolbar flat color="white">
            <v-menu
              ref="startMenu"
              v-model="startMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="start"
              transition="scale-transition"
              min-width="250px"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="start"
                  class="mt-0"
                  label="Ir a"
                  prepend-icon="event"
                  dense
                  readonly
                  outlined
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="start" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startMenu = false"
                  >Cancel</v-btn
                >
                <v-btn text color="primary" @click="$refs.startMenu.save(start)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
            <v-btn
              outlined
              class="ml-1"
              color="grey darken-2"
              @click="start = new Date().toISOString().slice(0, 10)"
              >Hoy</v-btn
            >
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="$refs.calendar.prev()"
            >
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="$refs.calendar.next()"
            >
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">{{
              $refs.calendar.title
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-select
              v-model="type"
              :items="typeOptions"
              label="Tipo"
              hide-details
              outlined
              dense
            ></v-select>
          </v-toolbar>
        </v-sheet>
        <!-- <v-btn fab small absolute class="mx-0" color="primary" @click="$refs.calendar.prev()">
          <v-icon dark>mdi-chevron-left</v-icon>
        </v-btn>-->
      </v-col>
      <!-- <v-col sm="1" lg="1">
        <v-btn
          fab
          small
          absolute
          color="secondary"
          @click="start = new Date().toISOString().slice(0, 10)"
        >
          <v-icon dark>mdi-calendar-range</v-icon>
        </v-btn>
      </v-col>-->
      <!-- <v-col sm="1" lg="1">
        <v-btn fab small absolute color="primary" @click="$refs.calendar.next()">
          <v-icon dark>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>-->
      <!-- <v-col sm="1" lg="1">
        <v-btn fab small absolute color="primary" @click="show_options = !show_options">
          <v-icon dark>mdi-cog-outline</v-icon>
        </v-btn>
      </v-col>-->
    </v-row>
    <v-row v-if="show_options">
      <v-col>
        <v-checkbox v-model="dark" label="Dark" hide-details></v-checkbox>
        <v-checkbox
          v-model="shortIntervals"
          label="Short intervals"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="shortMonths"
          label="Short months"
          hide-details
        ></v-checkbox>
        <v-checkbox
          v-model="shortWeekdays"
          label="Short weekdays"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-select
          v-model="color"
          :items="colorOptions"
          label="Color"
          class="mt-1"
          hide-details
          outlined
          dense
        ></v-select>

        <v-select
          v-if="hasIntervals"
          v-model="styleInterval"
          :items="styleIntervalOptions"
          dense
          outlined
          hide-details
          class="mt-1"
          label="Styling"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="mode"
          :items="modeOptions"
          dense
          outlined
          hide-details
          label="Event Overlap Mode"
          class="mt-1"
        ></v-select>
        <v-select
          v-model="weekdays"
          :items="weekdaysOptions"
          dense
          outlined
          hide-details
          label="Días de la Semana"
          class="mt-1"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <!-- <v-col sm="12" lg="3" class="mb-4 controls">
        <v-menu
          ref="startMenu"
          v-model="startMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="start"
          transition="scale-transition"
          min-width="290px"
          offset-y
        >
          <v-date-picker v-model="start" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="startMenu = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.startMenu.save(start)">OK</v-btn>
          </v-date-picker>
        </v-menu>
        <v-menu
          v-if="hasEnd"
          ref="endMenu"
          v-model="endMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="end"
          transition="scale-transition"
          min-width="290px"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end"
              class="mt-3"
              label="End Date"
              prepend-icon="event"
              dense
              readonly
              outlined
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="end" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="endMenu = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.endMenu.save(end)">OK</v-btn>
          </v-date-picker>
        </v-menu>

        <v-text-field
          v-if="type === 'custom-weekly'"
          v-model="minWeeks"
          dense
          outlined
          hide-details
          class="mt-3"
          label="Minimum Weeks"
          type="number"
        ></v-text-field>

        <v-select
          v-if="type === 'custom-daily'"
          v-model="maxDays"
          :items="maxDaysOptions"
          dense
          outlined
          hide-details
          class="mt-3"
          label="# of Days"
        ></v-select>
      </v-col>-->
      <v-col class="pl-1">
        <v-sheet>
          <v-calendar
            ref="calendar"
            v-model="start"
            :type="type"
            :start="start"
            :end="end"
            :min-weeks="minWeeks"
            :max-days="maxDays"
            :now="now"
            :dark="dark"
            :weekdays="weekdays"
            :first-interval="intervals.first"
            :interval-minutes="intervals.minutes"
            :interval-count="intervals.count"
            :interval-height="intervals.height"
            :interval-style="intervalStyle"
            :show-interval-label="showIntervalLabel"
            :short-intervals="shortIntervals"
            :short-months="shortMonths"
            :short-weekdays="shortWeekdays"
            :color="color"
            :events="allEvents"
            :event-overlap-mode="mode"
            :event-overlap-threshold="45"
            :event-color="getEventColor"
            locale="es"
            @mousedown:event="startDrag"
            @mousedown:time="startTime"
            @mousemove:time="mouseMove"
            @mouseup:time="endDrag"
            @mouseleave.native="cancelDrag"
            @click:event="showEvent"
          >
            <template #event="{ event, timed, eventSummary }">
              <div
                class="v-event-draggable rich-text-content"
                v-html="eventSummary()"
              ></div>
              <div
                v-if="timed"
                class="v-event-drag-bottom"
                @mousedown.stop="extendBottom(event)"
              ></div>
            </template>
          </v-calendar>
          <!-- @change="getEvents" -->
        </v-sheet>
      </v-col>
    </v-row>
    <b-modal id="modal-edit-event" title="Editar evento" size="lg" hide-footer>
      <SessionDateForm
        :allows_crud="allows_crud"
        :SessionDate="selectedOpen"
        @created="slotEventCreated"
        @updated="slotEventCreated"
        @deleted="slotEventDeleted"
        @delete="slotEventDeleted"
        @created_bulk="slotEventCreated"
      ></SessionDateForm>
    </b-modal>
  </v-sheet>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

const weekdaysDefault = [0, 1, 2, 3, 4, 5, 6];

const intervalsDefault = {
  first: 10,
  minutes: 45,
  count: 20,
  height: 48,
};

const stylings = {
  //   default(interval)
  default() {
    return undefined;
  },
  workday(interval) {
    const inactive =
      interval.weekday === 0 ||
      interval.weekday === 6 ||
      interval.hour < 9 ||
      interval.hour >= 17;
    const startOfHour = interval.minute === 0;
    const dark = this.dark;
    const mid = dark ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)";

    return {
      backgroundColor: inactive
        ? dark
          ? "rgba(0,0,0,0.4)"
          : "rgba(0,0,0,0.05)"
        : undefined,
      borderTop: startOfHour ? undefined : "1px dashed " + mid,
    };
  },
  past(interval) {
    return {
      backgroundColor: interval.past
        ? this.dark
          ? "rgba(0,0,0,0.4)"
          : "rgba(0,0,0,0.05)"
        : undefined,
    };
  },
};

export default {
  components: {
    SessionDateForm: () => import("./SessionDateForm"),
  },
  props: {
    sections: {
      type: Array,
      default() {
        return [];
      },
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    show_options: false,
    dark: false,
    startMenu: false,
    start: new Date().toISOString().slice(0, 10),
    endMenu: false,
    end: new Date().toISOString().slice(0, 10),
    nowMenu: false,
    minWeeks: 1,
    now: null,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    type: "week",
    typeOptions: [
      { text: "Diario", value: "day" },
      // { text: "5 Días", value: "4day" },
      { text: "Semanal", value: "week" },
      { text: "Mensual", value: "month" },
    ],
    mode: "stack",
    modeOptions: [
      { text: "Stack", value: "stack" },
      { text: "Column", value: "column" },
    ],
    weekdays: weekdaysDefault,
    weekdaysOptions: [
      { text: "Domingo - Sábado", value: weekdaysDefault },
      // { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      { text: "Lunes - Viernes", value: [1, 2, 3, 4, 5] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
    ],
    intervals: intervalsDefault,
    intervalsOptions: [
      { text: "Horario Laboral", value: intervalsDefault },
      {
        text: "Horario Completo",
        value: { first: 0, minutes: 60, count: 24, height: 48 },
      },
    ],
    maxDays: 7,
    maxDaysOptions: [
      { text: "7 days", value: 7 },
      { text: "5 days", value: 5 },
      { text: "4 days", value: 5 },
      { text: "3 days", value: 3 },
    ],
    styleInterval: "default",
    styleIntervalOptions: [
      { text: "Default", value: "default" },
      { text: "Workday", value: "workday" },
      { text: "Past", value: "past" },
    ],
    color: "primary",
    colorOptions: [
      { text: "Primary", value: "primary" },
      { text: "Secondary", value: "secondary" },
      { text: "Accent", value: "accent" },
      { text: "Red", value: "red" },
      { text: "Pink", value: "pink" },
      { text: "Purple", value: "purple" },
      { text: "Deep Purple", value: "deep-purple" },
      { text: "Indigo", value: "indigo" },
      { text: "Blue", value: "blue" },
      { text: "Light Blue", value: "light-blue" },
      { text: "Cyan", value: "cyan" },
      { text: "Teal", value: "teal" },
      { text: "Green", value: "green" },
      { text: "Light Green", value: "light-green" },
      { text: "Lime", value: "lime" },
      { text: "Yellow", value: "yellow" },
      { text: "Amber", value: "amber" },
      { text: "Orange", value: "orange" },
      { text: "Deep Orange", value: "deep-orange" },
      { text: "Brown", value: "brown" },
      { text: "Blue Gray", value: "blue-gray" },
      { text: "Gray", value: "gray" },
      { text: "Black", value: "black" },
    ],
    shortIntervals: true,
    shortMonths: true,
    shortWeekdays: true,
    selectedElement: null,
    selectedOpen: false,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      storedSections: names.SECTIONS,
      storedSessionDates: names.SESSION_DATES,
    }),
    selectedSections() {
      let list = [];
      this.sections.forEach((section_id) => {
        const instance = this.storedSections.find((x) => x.id == section_id);
        if (instance) list.push(instance);
        else console.log("Sección no encontrada: ", section_id);
      });
      return list;
    },
    sessionDateList() {
      let list = [];
      if (this.loading == true) return list;
      this.selectedSections.forEach((section) => {
        section.session_dates.forEach((session_date_id) => {
          let instance = this.storedSessionDates.find(
            (x) => x.id == session_date_id
          );
          if (instance) {
            let init_date = this.$moment(instance.start_date).format(
              "YYYY-MM-DD HH:mm"
            );
            let end_date = this.$moment(instance.end_date).format(
              "YYYY-MM-DD HH:mm"
            );
            list.push({
              id: instance.id,
              name: section.name,
              start: init_date,
              end: end_date,
              timed: false,
              color: section.color,
              section: section.id,
              parent: instance.parent,
            });
          } else console.log("Session Date no encontrada: ", session_date_id);
        });
      });
      return list;
    },
    allEvents() {
      return this.sessionDateList.concat(this.events);
    },
    intervalStyle() {
      return stylings[this.styleInterval].bind(this);
    },
    hasIntervals() {
      return (
        this.type in
        {
          week: 1,
          day: 1,
          "4day": 1,
          "custom-daily": 1,
        }
      );
    },
    hasEnd() {
      return (
        this.type in
        {
          "custom-weekly": 1,
          "custom-daily": 1,
        }
      );
    },
  },
  methods: {
    viewDay({ date }) {
      this.start = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    showIntervalLabel(interval) {
      return interval.minute === 0;
    },
    randomEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          timed: !allDay,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
        });
      }

      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    // -----------------
    startDrag({ event, timed }) {
      if (!this.allows_crud) return;
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      if (!this.allows_crud) return;
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        const createEnd = this.createStart + 45 * 60 * 1000;
        this.createEvent = {
          name: `Event #${this.events.length}`,
          color: this.rndElement(this.colors),
          start: this.createStart,
          end: createEnd,
          timed: true,
        };

        this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      if (!this.allows_crud) return;
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 45; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    showEvent(items) {
      if (!this.allows_crud) return;
      //{ nativeEvent, event }
      this.selectedElement = items.event;
      let session_date = this.allEvents.find((x) => x.id == items.event.id);
      session_date.start = items.event.start;
      session_date.end = items.event.end;
      this.selectedOpen = session_date;
      this.$bvModal.show("modal-edit-event");
      // nativeEvent.stopPropagation();
    },
    // -----------------
    getMatterName(matter_id) {
      this.$store.dispatch(names.FETCH_MATTER, matter_id).then((response) => {
        return response.name;
      });
      return "";
    },
    slotEventCreated() {
      this.$bvModal.hide("modal-edit-event");
      const index = this.events.findIndex((x) => x == this.selectedElement);
      if (index != -1) this.events.splice(index, 1);
    },
    slotEventDeleted(event) {
      this.$bvModal.hide("modal-edit-event");
      let index = this.events.findIndex((x) => x.id == event.id);
      if (index != -1) this.events.splice(index, 1);
      index = this.events.findIndex((x) => x == this.selectedElement);
      if (index != -1) this.events.splice(index, 1);
    },
  },
  watch: {
    selectedSections() {
      let counter = 0;
      this.selectedSections.forEach((element) => {
        element.session_dates.forEach((sd_id) => {
          const index = this.storedSessionDates.findIndex((x) => x.id == sd_id);
          if (index == -1) {
            counter += 1;
            this.loading = true;
            this.$store.dispatch(names.FETCH_SESSION_DATE, sd_id).then(() => {
              counter -= 1;
              if (counter == 0) this.loading = false;
            });
          }
        });
      });
    },
  },
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
    
<style scoped>
.controls {
  position: relative;
}
</style>